import { DEBUG, FOCUS_FEATURE_TIMEOUT } from '../../config';
import { isOnStartPage, runAfterTimeHasElapsed } from '../../helper/helper';
import { errorLog } from '../../helper/logging';
import { dispatchActivateFocusControls, dispatchDeactivateFocusControls } from '../../redux/actions/focus';
import { addLogDispatch } from '../../redux/actions/logs';
import reduxStore from '../../redux/store/index';
import { serializeContact } from '../../redux/utils/serializeContact';
import { dispatcherWebRTCManager } from '../webrtcManagers/DispatcherWebRTCManager';
import { WebRtcMessageDispatcher } from '../../types';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';

/**
 * send a message via webrtc
 * @param {object} message2Send
 * @param {boolean} ping - is heartbeat ping
 */
export const sendWebRtcMessage = (message2Send, ping = false) => {
    if (!isOnStartPage() && dispatcherWebRTCManager.userAgent !== null && dispatcherWebRTCManager.connectedConversation !== null) {
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;

        const send = () => {
            if (DEBUG) addLogDispatch(['senderObject', serializeContact(dispatcherWebRTCManager.sender)]);
            dispatcherWebRTCManager.sender
                .sendMessage(message)
                .then(function () {
                    if (DEBUG) addLogDispatch(['message send', message]);
                })
                .catch(err => {
                    // failed message attempts are not relevant if dispatcher is on start dashboard so don't log these
                    if (!isOnStartPage() && dispatcherWebRTCManager.userAgent !== null) {
                        if (DEBUG) addLogDispatch(['message send error', message, err]);
                        if (!ping) {
                            errorLog({
                                message: `Error sending message via rtc - dispatcher - ${message}`,
                                error: err,
                                eventId: 'MESSAGE_SEND',
                            });
                        }
                    }
                });
        };

        if (dispatcherWebRTCManager.sender) {
            send();
            return;
        }

        interval = window.setInterval(() => {
            if (DEBUG) addLogDispatch(['sendMessageInterval']);
            if (dispatcherWebRTCManager.sender) {
                send();
                clearInterval(interval);
            } else {
                if (intervalCount > 0) {
                    intervalCount -= 1;
                } else {
                    clearInterval(interval);
                    if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                }
            }
        }, 200);
    }
};

/***
 * Send caller latest caller data to conference users
 */

export const sendSessionData = () => {
    const excludeKeys = [
        'bystanderToken',
        'downloadable',
        'whiteLabeling',
        'images',
        'imageFormat',
        'notes',
        'photoPermission',
        'sessionEnd',
        'sessionOpen',
        'sessionStart',
        'streamRecordingPermission',
        'timeToLive',
        'user',
        'userId',
        'geoMap',
        'recordings',
        'fileUploads',
        'videoBackgroundImage',
        'logs',
        'phone',
        'dispatchCenter',
    ];

    const dataForInvitedUsers = Object.keys(reduxStore.getState().session)
        .filter(key => !excludeKeys.includes(key))
        .reduce((acc, key) => {
            acc[key] = reduxStore.getState().session[key];
            return acc;
        }, {});

    (dataForInvitedUsers as any).currentFocusFeature = reduxStore.getState().focus.currentFocusFeature;

    const message = {
        data: WebRtcMessageDispatcher.SESSION_DATA,
        sessionData: dataForInvitedUsers,
    };
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

/**
 * Send message to the conference users with application data
 */
export const sendApplicationData = () => {
    const { audioStreamIsActive, chatIsActive, gpsIsActive, externalStreamIsActive } = reduxStore.getState().application;

    sendToggleChat(chatIsActive, true);
    sendToggleGPS(gpsIsActive, true);
    sendAudioActivationStatus(audioStreamIsActive);
    sendToggleExternalStreaming(externalStreamIsActive);
};

/**
 * Send message to the conference users with session handling data
 */
export const sendSessionHandlingData = () => {
    const { activeDeviceId, devices } = reduxStore.getState().sessionHandlingSlice;

    const message = {
        data: WebRtcMessageDispatcher.SESSION_HANDLING_DATA,
        activeDeviceId: activeDeviceId,
        devices: devices,
    };
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

/**
 * Send message to the conference users with the audio status
 */
export const sendAudioActivationStatusFromStore = () => {
    sendAudioActivationStatus(reduxStore.getState().application.audioStreamIsActive);
};

export const sendAudioActivationStatus = status => {
    const message = {
        data: WebRtcMessageDispatcher.AUDIO_ACTIVATION_STATUS,
        audioIsActive: status,
    };
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

export const sendPing = () => {
    const message = {
        data: WebRtcMessageDispatcher.HEARTBEAT_PING,
    };

    sendWebRtcMessage(message, true);
};

export const sendCurrentFocusFeatureStatus = () => {
    if (reduxStore.getState().focus.currentFocusFeature !== null) {
        if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.CHAT) {
            sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
        } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.BIDI) {
            sendSetFeatureFocus(FOCUS_FEATURE_TYPE.BIDI);
        } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.SCREEN_SHARE) {
            sendSetFeatureFocus(FOCUS_FEATURE_TYPE.SCREEN_SHARE);
        } else if (reduxStore.getState().focus.currentFocusFeature === FOCUS_FEATURE_TYPE.EXTERNAL_STREAM) {
            sendSetFeatureFocus(FOCUS_FEATURE_TYPE.EXTERNAL_STREAM);
        }
    }
};

export const sendDispatcherLeftToCaller = async () => {
    const message = {
        data: WebRtcMessageDispatcher.DISPATCHER_LEFT,
    };

    sendWebRtcMessage(message, true);
};

export const sendDispatcherLeftToUser = contact => {
    const message = {
        data: WebRtcMessageDispatcher.DISPATCHER_LEFT,
    };

    dispatcherWebRTCManager.sendMessageToUser(message, contact);
};

export const sendJoinRequestGrantedToUser = contact => {
    const message = {
        data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
    };

    dispatcherWebRTCManager.sendMessageToUser(message, contact);
};

export const sendJoinRequestDeclinedToUser = contact => {
    const message = {
        data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_DECLINED,
    };

    dispatcherWebRTCManager.sendMessageToUser(message, contact);
};

export const sendHandoverSessionToCallerDevice = () => {
    const message = {
        data: WebRtcMessageDispatcher.HANDOVER_SESSION,
    };

    sendWebRtcMessage(message);
};

export const sendDispatcherLeftToConferenceUsers = () => {
    const message = {
        data: WebRtcMessageDispatcher.DISPATCHER_LEFT,
    };

    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

export const sendSetFeatureFocus = feature => {
    const message = {
        data: WebRtcMessageDispatcher.SET_FEATURE_FOCUS,
        state: feature,
    };

    sendWebRtcMessage(message);
    dispatchDeactivateFocusControls();
    runAfterTimeHasElapsed(dispatchActivateFocusControls, FOCUS_FEATURE_TIMEOUT);
};

export const sendToggleExternalStreaming = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_EXTERNAL_STREAMING,
        state: activeState,
    };

    sendWebRtcMessage(message);
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

export const sendToggleMicrophone = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_MICROPHONE,
        state: activeState,
    };

    sendWebRtcMessage(message);
};

/**
 * toggle gps
 * @param {boolean} activeState
 * @param {boolean} onlyToConference - send data only to the conference users
 */
export const sendToggleGPS = (activeState, onlyToConference = false) => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_GPS,
        state: activeState,
    };

    if (!onlyToConference) sendWebRtcMessage(message);
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

/**
 * toggle video
 * @param {boolean} activeState
 */
export const sendToggleVideo = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_VIDEO,
        state: activeState,
        id: reduxStore.getState().application.deviceId,
        name: reduxStore.getState().application.deviceName,
    };

    sendWebRtcMessage(message);
};

/**
 * toggle chat
 * @param {boolean} activeState
 * @param {boolean} onlyToConference - send data only to the conference users
 */
export const sendToggleChat = (activeState, onlyToConference = false) => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_CHAT,
        state: activeState,
    };
    if (!onlyToConference) sendWebRtcMessage(message);
    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

/**
 * toggle caller audio
 * @param {boolean} isActive
 */
export const sendToggleAudioStream = isActive => {
    dispatcherWebRTCManager.handleDispatcherAudioStream(isActive);

    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_AUDIO_STREAM,
        state: isActive,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle stream recording
 * @param {boolean} isActive
 */
export const sendToggleStreamRecording = isActive => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_STREAM_RECORDING,
        state: isActive,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle snapshot
 * @param {boolean} activeState
 */
export const sendToggleSnapshot = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_SNAPSHOT,
        state: activeState,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle pointer
 * @param {boolean} activeState
 */
export const sendTogglePointer = activeState => {
    const message = {
        data: 'togglePointer',
        state: activeState,
    };
    sendWebRtcMessage(message);
};

/**
 * send pointer position
 * @param {object} position
 */
export const sendPointerPosition = position => {
    const message = {
        data: 'pointerPosition',
        state: position,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle hdsend
 * @param {boolean} activeState
 */
export const sendToggleHDSend = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_HD_SEND,
        state: activeState,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle hdsend
 * @param {boolean} activeState
 */
export const sendScreenshareToggled = activeState => {
    const message = {
        data: 'screenShareToggled',
        state: activeState,
    };

    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

export const sendMuteAllMicrophones = () => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_MICROPHONE,
    };

    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};

export const sendMuteMicrophone = conferenceUser => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_MICROPHONE,
    };

    dispatcherWebRTCManager.sendMessageToUser(message, conferenceUser);
};

export const sendBidiIsDeactivated = () => {
    const message = {
        data: WebRtcMessageDispatcher.BIDI_IS_DEACTIVATED,
    };

    sendWebRtcMessage(message);
};

/**
 * send transfer ended message
 */
export const sendFileTransferEnded = () => {
    const message = {
        data: WebRtcMessageDispatcher.HD_FILE_TRANSFER_ENDED,
    };
    sendWebRtcMessage(message);
};

/**
 * sends the last new points
 * @param {string} points
 */
export const sendDispatcherPaintPoints = points => {
    const message = {
        data: WebRtcMessageDispatcher.DISPATCHER_PAINT_POINTS,
        points,
    };
    sendWebRtcMessage(message);
};

/**
 * sends the undo message for the last painted points
 */
export const sendUndoPaintPoints = () => {
    const message = {
        data: WebRtcMessageDispatcher.UNDO_LAST_PAINT_POINTS,
    };
    sendWebRtcMessage(message);
};

/**
 * sends the delete message for all painted points
 */
export const sendDeletePaintPoints = () => {
    const message = {
        data: WebRtcMessageDispatcher.DELETE_ALL_PAINT_POINTS,
    };
    sendWebRtcMessage(message);
};

/**
 * toggle draw
 * @param {boolean} activeState
 */
export const sendToggleDraw = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.TOGGLE_DRAW,
        state: activeState,
    };
    sendWebRtcMessage(message);
};

/**
 * allowPainting
 * @param {boolean} activeState
 */
export const sendAllowPaintingDispatcher = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.ALLOW_PAINTING,
        state: activeState,
    };
    sendWebRtcMessage(message);
};

/**
 * allowPainting to conference users
 * @param {boolean} activeState
 */
export const sendAllowPaintingToConferenceUsers = activeState => {
    const message = {
        data: WebRtcMessageDispatcher.ALLOW_PAINTING,
        state: activeState,
    };

    dispatcherWebRTCManager.sendMessageToAllConferenceUsers(message);
};
/**
 * toggle conferencing
 * @param {boolean} activeState
 */
export const sendToggleConferencing = activeState => {
    const message = {
        data: 'toggleConferencing',
        state: activeState,
    };
    sendWebRtcMessage(message);
};
