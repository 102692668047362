import {
    SET_AUDIO_STREAM_PERMISSION_GRANTED,
    SET_AUDIO_STREAM_PERMISSION_DENIED,
    RESET_AUDIO_STREAM_PERMISSION,
    SET_SCREENSHARE_PERMISSION_GRANTED,
    SET_SCREENSHARE_PERMISSION_DENIED,
    SET_VIDEO_STREAM_PERMISSION_GRANTED,
    SET_VIDEO_STREAM_PERMISSION_DENIED,
    SET_JOIN_CONVERSATION_PERMISSION_GRANTED,
    SET_JOIN_CONVERSATION_PERMISSION_DENIED,
    SET_DRAW_SNAPSHOT_PERMISSION_GRANTED,
    SET_DRAW_SNAPSHOT_PERMISSION_DENIED,
} from '../actions/permissions';

export const initialState = {
    screensharePermission: null,
    audioStreamPermission: null,
    videoStreamPermission: null,
    joinConversationPermission: null,
    drawSnapshotPermission: null,
};

const permissionReducer = (state = initialState, action) => {
    if (action.type === SET_AUDIO_STREAM_PERMISSION_GRANTED) {
        return { ...state, audioStreamPermission: true };
    }

    if (action.type === SET_AUDIO_STREAM_PERMISSION_DENIED) {
        return { ...state, audioStreamPermission: false };
    }

    if (action.type === RESET_AUDIO_STREAM_PERMISSION) {
        return { ...state, audioStreamPermission: null };
    }

    if (action.type === SET_SCREENSHARE_PERMISSION_GRANTED) {
        return { ...state, screensharingPermission: true };
    }

    if (action.type === SET_SCREENSHARE_PERMISSION_DENIED) {
        return { ...state, screensharingPermission: false };
    }

    if (action.type === SET_VIDEO_STREAM_PERMISSION_GRANTED) {
        return { ...state, videoStreamPermission: true };
    }

    if (action.type === SET_VIDEO_STREAM_PERMISSION_DENIED) {
        return { ...state, videoStreamPermission: false };
    }

    if (action.type === SET_JOIN_CONVERSATION_PERMISSION_GRANTED) {
        return { ...state, joinConversationPermission: true };
    }

    if (action.type === SET_JOIN_CONVERSATION_PERMISSION_DENIED) {
        return { ...state, joinConversationPermission: false };
    }

    if (action.type === SET_DRAW_SNAPSHOT_PERMISSION_GRANTED) {
        return { ...state, drawSnapshotPermission: true };
    }

    if (action.type === SET_DRAW_SNAPSHOT_PERMISSION_DENIED) {
        return { ...state, drawSnapshotPermission: false };
    }

    return state;
};

export default permissionReducer;
