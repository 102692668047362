import React, { useCallback, useEffect, useState } from 'react';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import { createKpiLog, replaceText } from '../../helper/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { C_LOST, DISPLAY_ONLY_IN_SESSION, HD_PHOTO_NOTIFICATION_TIMEOUT, IMAGE_NAME, PHOTO_LIMIT } from '../../config';
import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { addSessionImageDispatch, dispatchChangeImageFormat } from '../../redux/actions/session';
import { errorLog } from '../../helper/logging';
import { setHdPhotoDisclaimerNotClickedDispatch } from '../../redux/actions/disclaimers';
import { activateHDSendDispatch, deactivateHDSendDispatch, deactivateSnapshotDispatch, dispatchCallerFileIsNotBusy } from '../../redux/actions/application';
import { dispatchSetFocusWindowLiveVideo } from '../../redux/actions/focus';
import { getSnapshotMimeType } from '../../api/backendApi';
import ConnectionOverlay from '../Globals/ConnectionOverlay';
import Screenshot from '../Icons/Screenshot';
import HD from '../Icons/HD';
import './Snapshots.scss';
import { sendSetFeatureFocus } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { FOCUS_FEATURE_TYPE } from '../../types/focus.types';
import complexObjectStorage from '../../helper/complexObjectStorage';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';

/**
 * Snapshots
 * Shows the snapshots taken and provides the button to take a snapshot.
 * Snapshots are extracted from a running video stream.
 * Snapshots are immediately downloaded if autodownload is enabled.
 */

export default function SnapshotButtons() {
    const snapshotIsActive = useSelector((state: RootState) => state.application.snapshotIsActive);
    const hdSendIsActive = useSelector((state: RootState) => state.application.hdSendIsActive);
    const snapshotPaused = useSelector((state: RootState) => state.application.snapshotPaused);
    const imageFormat = useSelector((state: RootState) => state.session.imageFormat);
    const isConnected = useSelector((state: RootState) => state.connection.isConnected);
    const connectionStatus = useSelector((state: RootState) => state.connection.status);
    const texts = useSelector((state: RootState) => state.texts.texts);
    const hdSendFeature = useSelector((state: RootState) => state.features.hdSendFeature);
    const snapshotFeature = useSelector((state: RootState) => state.features.snapshotFeature);
    const callerFileIsBusy = useSelector((state: RootState) => state.application.callerFileIsBusy);
    const images = useSelector((state: RootState) => state.session.images);
    const photoPermission = useSelector((state: RootState) => state.session.photoPermission);
    const videoIsActive = useSelector((state: RootState) => state.application.videoIsActive);
    const hdPhotoDisclaimerClicked = useSelector((state: RootState) => state.disclaimers.hdPhotoDisclaimerClicked);
    const currentNotifications = useSelector((state: RootState) => state.notifications.currentNotifications);
    const isHeadMountedDisplayDevice = useSelector((state: RootState) => state.sessionHandlingSlice.isHeadMountedDisplayDevice);
    const callerStream = useSelector((state: RootState) => state.streamSlice.callerStream);

    const [hdButtonIsClicked, setHdButtonIsClicked] = useState(false);
    const [hdButtonIsDisabled, setHdButtonIsDisabled] = useState(false);
    const [hdSendButtonText, setHdSendButtonText] = useState('');
    const [snapshotButtonText, setSnapshotButtonText] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [showHint, setShowHint] = useState(false);

    const snapshot = () => {
        if (!photoPermission) return;
        const currentImageFormat = imageFormat === 'PNG' ? 'image/png' : 'image/jpeg';
        // get full stream from storage via streamId
        const stream = complexObjectStorage[callerStream.streamId];
        stream
            .takeSnapshot({ outputMimeType: currentImageFormat })
            .then(snapshot => {
                addSessionImageDispatch({
                    image: snapshot,
                    time: new Date().getTime(),
                    type: currentImageFormat,
                    quality: 'standard',
                });

                const fileName = `${IMAGE_NAME}_${dispatcherWebRTCManager.webRtcSessionId}_${dispatcherAuthManager.bystanderToken}_${
                    images[images.length - 1].time
                }.${imageFormat}`;

                const additionalStates = {
                    0: fileName,
                };

                createKpiLog('infoScreenshotTaken', '', additionalStates);
            })
            .catch(e => {
                errorLog({
                    message: 'apiRTC not a available - caller',
                    error: e,
                    eventId: 'APIRTC_SCRIPT_LOADING_CALLER',
                });
            });
    };

    const allowHdPhoto = () => {
        setHdButtonIsClicked(true);
        setHdButtonIsDisabled(true);

        if (hdPhotoDisclaimerClicked === null || hdPhotoDisclaimerClicked === false) {
            addNotificationAndShowDispatch('warning.hdphoto.notice', 'warning', DISPLAY_ONLY_IN_SESSION);

            createKpiLog('infoHdPhotoDisclaimer', 'displayed');
        }
        deactivateHDSendDispatch();
        activateHDSendDispatch();
        dispatchSetFocusWindowLiveVideo();
        sendSetFeatureFocus(FOCUS_FEATURE_TYPE.LIVE_VIDEO);
    };

    useEffect(() => {
        currentNotifications.forEach(notification => {
            if (notification.message === 'warning.hdphoto.notice') {
                setTimeout(() => {
                    setHdPhotoDisclaimerNotClickedDispatch();
                    createKpiLog('infoHdPhotoDisclaimer', 'timeout');
                    hideAndRemoveNotificationsDispatch(notification.type);
                }, HD_PHOTO_NOTIFICATION_TIMEOUT);
            }
        });
    }, [currentNotifications]);

    const handleButtonsState = useCallback(() => {
        const photoLimitReached = images.length >= PHOTO_LIMIT;
        const isNotConnected = !isConnected || connectionStatus === C_LOST;
        const isActiveAndNotPaused = snapshotIsActive && !snapshotPaused;

        switch (true) {
            case isActiveAndNotPaused && photoPermission === false && snapshotFeature && !photoLimitReached && !snapshotPaused:
                setHdButtonIsDisabled(true || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(true || isNotConnected);
                setSnapshotButtonText('snapshot.button.waiting');
                setHdSendButtonText('hdsend.button.waiting');
                break;
            case isActiveAndNotPaused && photoPermission && !hdButtonIsClicked && !photoLimitReached:
                setHdButtonIsDisabled(false || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(false || isNotConnected);
                setSnapshotButtonText('snapshot.button.enabled');
                setHdSendButtonText('hdsend.button.enabled');
                break;
            case isActiveAndNotPaused && photoPermission && hdButtonIsClicked && !photoLimitReached:
                setHdButtonIsDisabled(true || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(false || isNotConnected);
                setSnapshotButtonText('snapshot.button.enabled');
                setHdSendButtonText('hdsend.button.enabled');
                break;
            case isActiveAndNotPaused && !photoPermission && !snapshotFeature && !photoLimitReached:
                setHdButtonIsDisabled(false || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(false || isNotConnected);
                setSnapshotButtonText('snapshot.button.enabled');
                setHdSendButtonText('hdsend.button.enabled');
                break;
            case photoLimitReached:
                setHdButtonIsDisabled(true || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(true || isNotConnected);
                setSnapshotButtonText('snapshot.limit.reached');
                setHdSendButtonText('hdsend.limit.reached');
                break;
            default:
                setHdButtonIsDisabled(true || isNotConnected || isHeadMountedDisplayDevice);
                setIsDisabled(true || isNotConnected);
                setHdButtonIsClicked(false);
                setSnapshotButtonText('snapshot.button.disabled');
                setHdSendButtonText('hdsend.button.disabled');
                break;
        }
    }, [
        connectionStatus,
        hdButtonIsClicked,
        images.length,
        isConnected,
        photoPermission,
        snapshotFeature,
        snapshotIsActive,
        snapshotPaused,
        isHeadMountedDisplayDevice,
    ]);

    useEffect(() => {
        const setSnapshotMimeType = async () => {
            dispatchChangeImageFormat(await getSnapshotMimeType());
        };
        setSnapshotMimeType();
    }, []);

    useEffect(() => {
        if (!isConnected) {
            deactivateSnapshotDispatch();
        }

        handleButtonsState();
    }, [isConnected, handleButtonsState]);

    useEffect(() => {
        if (!callerFileIsBusy) {
            setHdButtonIsClicked(false);
            setHdButtonIsDisabled(false);
        }

        handleButtonsState();
    }, [callerFileIsBusy, handleButtonsState]);

    useEffect(() => {
        if (!videoIsActive) {
            dispatchCallerFileIsNotBusy();
            deactivateHDSendDispatch();
        }

        handleButtonsState();
    }, [videoIsActive, handleButtonsState]);

    useEffect(() => {
        setShowHint(isConnected && hdSendIsActive);
    }, [isConnected, hdSendIsActive]);

    return (
        <div className="snapshots">
            <div className="snapshots connectionOverlay">
                <ConnectionOverlay />
            </div>
            <div className="snapshots__button">
                {snapshotFeature && (
                    <div>
                        <button disabled={isDisabled} onClick={snapshot} className="btn btn--primary">
                            <Screenshot />
                            {replaceText(texts, snapshotButtonText)}
                        </button>
                    </div>
                )}
                {hdSendFeature && (
                    <div>
                        <button
                            disabled={hdButtonIsDisabled}
                            onClick={allowHdPhoto}
                            className="btn btn--primary"
                            title={isHeadMountedDisplayDevice ? replaceText(texts, 'toggle.disabled.by.hmd') : ''}>
                            <HD />
                            {replaceText(texts, hdSendButtonText)}
                        </button>
                        {showHint && (
                            <div className="snapshots__hint">
                                {callerFileIsBusy ? <>{replaceText(texts, 'hdsend.waiting.busy')}</> : <>{replaceText(texts, 'hdsend.waiting.caller')}</>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
