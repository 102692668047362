import { addConferenceUserStream, addDispatcherAudioStream, addDispatcherBidiStream, addDispatcherStream, removeConferenceUserStream, removeDispatcherAudioStream, removeDispatcherBidiStream, removeDispatcherStream } from '../../redux/actionCreators/actionCreators';
import store from '../../redux/store';
import { callerWebRTCManager } from '../webrtcManagers/CallerWebRTCManager';
import reduxStore from '../../redux/store';
import { activateBidiOtherDispatch, activateScreenshareDispatch, deactivateBidiOtherDispatch, deactivateScreenshareDispatch, dispatchStartStreamRecording, dispatchStopStreamRecording } from '../../redux/actions/application';
import { WebRtcManagerType } from '../../types';
import { dispatchAddStreamInfo, dispatchRemoveStreamInfo } from '../../redux/actions/conferencing';
import { serializeStreamInfo } from '../../redux/utils/serializeStreamInfo';
import { dispatchToggleAudioMuted } from '../../redux/actions/invitedUsers';
import { getComplexObject } from '../../helper/complexObjectStorage';

// incoming stream events to caller
export const loadEventListenersCaller = () => {
    callerWebRTCManager.connectedConversation
        .on('streamAdded', addStream)
        .on('streamRemoved', removeStream)
        .on('streamListChanged', changeStream)
        .on('contactLeft', handleContactLeftCaller)
        .on('recordingStarted', recordingInfo => {
            dispatchStartStreamRecording();
        })
        .on('recordingStopped', recordingInfo => {
            dispatchStopStreamRecording();
        });
};

export const unloadEventListenersCaller = () => {
    callerWebRTCManager.connectedConversation
        .removeListener('streamAdded', addStream)
        .removeListener('streamRemoved', removeStream)
        .removeListener('streamListChanged', changeStream)
        .removeListener('contactLeft', handleContactLeftCaller)
        .removeListener('recordingStarted')
        .removeListener('recordingStopped');
};

// Add incoming stream

const addStream = stream => {
    // incoming dispatcher stream
    if (stream.contact.userData.username === 'dispatcher') {
        if (stream.type === 'audio') {
            store.dispatch(addDispatcherAudioStream(stream));
            handleIncomingAudio(stream);
        }
        if (stream.type === 'video') {
            reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                if (stream.streamId === streamInfo.streamId) {
                    // dispatcher only publishes video for screen sharing and bidi
                    if (!streamInfo.isScreensharing) {
                        if (streamInfo.context.bidi) {
                            let bidiContainer = document.getElementById('bidi-container-caller');
                            if (bidiContainer && bidiContainer !== null) {
                                bidiContainer.innerHTML = '';
                            }
                            store.dispatch(addDispatcherBidiStream(stream));
                            addBidiStreamToCallerContainer(stream);
                            activateBidiOtherDispatch();
                        }
                    }
                    if (streamInfo.isScreensharing) {
                        if (streamInfo.context.screenshare) {
                            let screenshareContainer = document.getElementById('screenshare-container-caller');
                            if (screenshareContainer && screenshareContainer !== null) {
                                screenshareContainer.innerHTML = '';
                            }
                            store.dispatch(addDispatcherStream(stream));
                            addScreenshareStreamToCallerContainer(stream);
                            activateScreenshareDispatch(WebRtcManagerType.CALLER);
                        }
                    }
                }
            });
        }
    } else {
        // incoming conference user audio stream
        store.dispatch(addConferenceUserStream(stream));
        handleIncomingAudio(stream);
    }
};

const handleIncomingAudio = stream => {
    if (!reduxStore.getState().application.audioIsMuted) {
        readdAllStreamsToContainerCaller();
    }
};

const removeStream = stream => {
    if (stream.contact.userData.username === 'dispatcher') {
        if (stream.type === 'video') {
            if (reduxStore.getState().streamSlice.dispatcherScreenshareStream) {
                if (stream.streamId === reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId) {
                    // dispatcher screen share stream
                    handleRemovalOfIncomingStream(stream);
                    store.dispatch(removeDispatcherStream(stream));
                    deactivateScreenshareDispatch();
                }
            }
            if (reduxStore.getState().streamSlice.dispatcherBidiStream) {
                if (stream.streamId === reduxStore.getState().streamSlice.dispatcherBidiStream.streamId) {
                    // dispatcher bidi stream
                    handleRemovalOfIncomingStream(stream);
                    store.dispatch(removeDispatcherBidiStream(stream));
                    deactivateBidiOtherDispatch();
                }
            }
            reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                if (stream.streamId === streamInfo.streamId) {
                    if (!streamInfo.isScreensharing && !streamInfo.context.recording) {
                        handleRemovalOfIncomingStream(stream);
                    }
                }
            });
        }
    }

    // if audio stream is active, proceed
    if (reduxStore.getState().streamSlice.dispatcherAudioStream !== null) {
        if (stream.type !== 'video') {
            if (stream.streamId === reduxStore.getState().streamSlice.dispatcherAudioStream.streamId) {
                // dispatcher audio stream
                store.dispatch(removeDispatcherAudioStream(stream));
                handleRemovalOfIncomingStream(stream);
            } else {
                // conference user stream
                store.dispatch(removeConferenceUserStream(stream));
                handleRemovalOfIncomingStream(stream);
            }
        }
    }
};

const handleRemovalOfIncomingStream = stream => {
    if (stream.type === 'video') {
        if (
            reduxStore.getState().streamSlice.dispatcherBidiStream &&
            reduxStore.getState().streamSlice.dispatcherBidiStream.streamId &&
            reduxStore.getState().streamSlice.dispatcherBidiStream.streamId === stream.streamId
        ) {
            stream.removeFromDiv('bidi-container-caller', 'bidi-stream-' + stream.streamId);
            let bidiContainer = document.getElementById('bidi-container-caller');
            if (bidiContainer && bidiContainer !== null) {
                bidiContainer.innerHTML = '';
            }
        } else if (
            reduxStore.getState().streamSlice.dispatcherScreenshareStream &&
            reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId &&
            reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId === stream.streamId
        ) {
            stream.removeFromDiv('screenshare-container-caller', 'screeenshare-stream-' + stream.streamId);
            let screenshareContainer = document.getElementById('screenshare-container-caller');
            if (screenshareContainer && screenshareContainer !== null) {
                screenshareContainer.innerHTML = '';
            }
        }
    } else {
        stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId);
    }
};

const changeStream = (streamInfo) => {
    if (streamInfo.isRemote === true) {
        if (streamInfo.listEventType === 'added') {
            dispatchAddStreamInfo(serializeStreamInfo(streamInfo));
            callerWebRTCManager.connectedConversation
                .subscribeToMedia(streamInfo.streamId)
                .then(stream => {
                    console.log('subscribeToMedia succeeded');

                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                })
                .catch(err => {
                    console.error('subscribeToMedia error', err);
                });
        }
        if (streamInfo.listEventType === 'removed') {
            dispatchRemoveStreamInfo(streamInfo.streamId);
        }
        if (streamInfo.listEventType === 'updated') {
            for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                if (streamInfo.streamId === previousStreamInfo.streamId) {
                    dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                    dispatchAddStreamInfo(serializeStreamInfo(streamInfo));

                    // toggles the audio muted information in the conference user list
                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                }
            }
        }
    }
};

// Contacts

const handleContactLeftCaller = contactInfo => {
    if (contactInfo.userData.username === 'dispatcher') {
        if (callerWebRTCManager.connectedConversation !== null) {
            callerWebRTCManager.connectedConversation.leave();
        }
        if (callerWebRTCManager.disconnectCallback) {
            callerWebRTCManager.disconnectCallback();
            callerWebRTCManager.clearAllTimeouts();
            clearInterval(callerWebRTCManager.checkHeartbeatInterval);
        }
    }
};

// ******************************************
// ADD AND REMOVE STREAMS TO CONTAINER CALLER

export const addBidiStreamToCallerContainer = stream => {
    stream.addInDiv('bidi-container-caller', 'bidi-stream-' + stream.streamId, {}, true);
};

export const addScreenshareStreamToCallerContainer = stream => {
    stream.addInDiv('screenshare-container-caller', 'screeenshare-stream-' + stream.streamId, {}, true);
};

export const addAllStreamsToContainerCaller = () => {
    if (reduxStore.getState().streamSlice.dispatcherAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.dispatcherAudioStream.streamId);
        stream.addInDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            // fetch stream object from complex object storage
            const stream = getComplexObject(reduxStream.streamId);
            stream.addInDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerCaller = () => {
    if (reduxStore.getState().streamSlice.dispatcherAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.dispatcherAudioStream.streamId);
        stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            const stream = getComplexObject(reduxStream.streamId);
            stream.removeFromDiv('audio-container-caller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};


export const readdAllStreamsToContainerCaller = () => {
    removeAllStreamsFromContainerCaller();
    addAllStreamsToContainerCaller();
};