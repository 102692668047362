/**
 * All used action types for redux flow
 */

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const SHOW_ERRORS = 'SHOW_ERRORS';
export const SHOW_INFOS = 'SHOW_INFOS';
export const SHOW_WARNINGS = 'SHOW_WARNINGS';
export const SHOW_PENDING = 'SHOW_PENDING';
export const HIDE_ERRORS = 'HIDE_ERRORS';
export const HIDE_INFOS = 'HIDE_INFOS';
export const HIDE_WARNINGS = 'HIDE_WARNINGS';
export const HIDE_PENDING = 'HIDE_PENDING';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const REMOVE_INFOS = 'REMOVE_INFOS';
export const REMOVE_WARNINGS = 'REMOVE_WARNINGS';
export const REMOVE_PENDING = 'REMOVE_PENDING';
export const ADD_LOG = 'ADD_LOG';

export const SET_TEXTS = 'SET_TEXTS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CALLER_LANGUAGE = 'SET_CALLER_LANGUAGE';
export const SET_CONFERENCE_LANGUAGE = 'SET_CONFERENCE_LANGUAGE';

export const ACTIVATE_CHAT = 'ACTIVATE_CHAT';
export const DEACTIVATE_CHAT = 'DEACTIVATE_CHAT';
export const ENABLE_CHAT = 'ENABLE_CHAT';
export const DISABLE_CHAT = 'DISABLE_CHAT';
export const ACTIVATE_GPS = 'ACTIVATE_GPS';
export const DEACTIVATE_GPS = 'DEACTIVATE_GPS';
export const ENABLE_GPS = 'ENABLE_GPS';
export const DISABLE_GPS = 'DISABLE_GPS';
export const ACTIVATE_VIDEO = 'ACTIVATE_VIDEO';
export const DEACTIVATE_VIDEO = 'DEACTIVATE_VIDEO';
export const ENABLE_VIDEO = 'ENABLE_VIDEO';
export const DISABLE_VIDEO = 'DISABLE_VIDEO';
export const ACTIVATE_SNAPSHOT = 'ACTIVATE_SNAPSHOT';
export const DEACTIVATE_SNAPSHOT = 'DEACTIVATE_SNAPSHOT';
export const ENABLE_SNAPSHOT = 'ENABLE_SNAPSHOT';
export const DISABLE_SNAPSHOT = 'DISABLE_SNAPSHOT';
export const PAUSE_SNAPSHOT = 'PAUSE_SNAPSHOT';
export const UNPAUSE_SNAPSHOT = 'UNPAUSE_SNAPSHOT';
export const ACTIVATE_POINTER = 'ACTIVATE_POINTER';
export const DEACTIVATE_POINTER = 'DEACTIVATE_POINTER';
export const ENABLE_POINTER = 'ENABLE_POINTER';
export const DISABLE_POINTER = 'DISABLE_POINTER';
export const ADD_VIDEO_STREAM = 'ADD_VIDEO_STREAM';
export const REMOVE_VIDEO_STREAM = 'REMOVE_VIDEO_STREAM';
export const ACTIVATE_HD_SEND = 'ACTIVATE_HD_SEND';
export const DEACTIVATE_HD_SEND = 'DEACTIVATE_HD_SEND';
export const ACTIVATE_DRAW = 'ACTIVATE_DRAW';
export const DEACTIVATE_DRAW = 'DEACTIVATE_DRAW';
export const ENABLE_DRAW = 'ENABLE_DRAW';
export const DISABLE_DRAW = 'DISABLE_DRAW';
export const ACTIVATE_CONFERENCING = 'ACTIVATE_CONFERENCING';
export const DEACTIVATE_CONFERENCING = 'DEACTIVATE_CONFERENCING';
export const ENABLE_CONFERENCING = 'ENABLE_CONFERENCING';
export const DISABLE_CONFERENCING = 'DISABLE_CONFERENCING';
export const ACTIVATE_AUDIO_STREAM = 'ACTIVATE_AUDIO_STREAM';
export const DEACTIVATE_AUDIO_STREAM = 'DEACTIVATE_AUDIO_STREAM';
export const ENABLE_AUDIO_STREAM = 'ENABLE_AUDIO_STREAM';
export const DISABLE_AUDIO_STREAM = 'DISABLE_AUDIO_STREAM';
export const ENABLE_BIDI = 'ENABLE_BIDI';
export const DISABLE_BIDI = 'DISABLE_BIDI';
export const ACTIVATE_BIDI = 'ACTIVATE_BIDI';
export const DEACTIVATE_BIDI = 'DEACTIVATE_BIDI';
export const ACTIVATE_BIDI_BLUR = 'ACTIVATE_BIDI_BLUR';
export const DEACTIVATE_BIDI_BLUR = 'DEACTIVATE_BIDI_BLUR';
export const ACTIVATE_BIDI_BACKGROUND = 'ACTIVATE_BIDI_BACKGROUND';
export const DEACTIVATE_BIDI_BACKGROUND = 'DEACTIVATE_BIDI_BACKGROUND';
export const ENABLE_STREAM_RECORDING = 'ENABLE_STREAM_RECORDING';
export const DISABLED_STREAM_RECORDING = 'DISABLED_STREAM_RECORDING';
export const ACTIVATE_STREAM_RECORDING = 'ACTIVATE_STREAM_RECORDING';
export const DEACTIVATE_STREAM_RECORDING = 'DEACTIVATE_STREAM_RECORDING';
export const ENABLE_AUTO_STREAM_RECORDING = 'ENABLE_AUTO_STREAM_RECORDING';
export const DISABLE_AUTO_STREAM_RECORDING = 'DISABLE_AUTO_STREAM_RECORDING';
export const ENABLE_SCREEN_SHARE = 'ENABLE_SCREEN_SHARE';
export const DISABLE_SCREEN_SHARE = 'DISABLE_SCREEN_SHARE';
export const ACTIVATE_SCREEN_SHARE = 'ACTIVATE_SCREEN_SHARE';
export const DEACTIVATE_SCREEN_SHARE = 'DEACTIVATE_SCREEN_SHARE';

export const RESET_ALL = 'RESET_ALL';

export const ENABLE_FEATURE_GEOSMS = 'ENABLE_FEATURE_GEOSMS';
export const ENABLE_FEATURE_SNAPSHOT = 'ENABLE_FEATURE_SNAPSHOT';
export const ENABLE_FEATURE_POINTER = 'ENABLE_FEATURE_POINTER';
export const ENABLE_FEATURE_SESSION_DOWNLOAD = 'ENABLE_FEATURE_SESSION_DOWNLOAD';
export const ENABLE_FEATURE_LOGO_UPLOAD = 'ENABLE_FEATURE_LOGO_UPLOAD';
export const ENABLE_FEATURE_PHONEBOOK = 'ENABLE_FEATURE_PHONEBOOK';
export const ENABLE_FEATURE_SMS_GUIDE = 'ENABLE_FEATURE_SMS_GUIDE';
export const ENABLE_FEATURE_HD_SEND = 'ENABLE_FEATURE_HD_SEND';
export const ENABLE_FEATURE_DRAW = 'ENABLE_FEATURE_DRAW';
export const ENABLE_FEATURE_AUDIO = 'ENABLE_FEATURE_AUDIO';
export const ENABLE_FEATURE_INVITE = 'ENABLE_FEATURE_INVITE';
export const ENABLE_FEATURE_BIDI = 'ENABLE_FEATURE_BIDI';
export const ENABLE_FEATURE_NOTES = 'ENABLE_FEATURE_NOTES';
export const ENABLE_FEATURE_DISCLAIMER_CALLER = 'ENABLE_FEATURE_DISCLAIMER_CALLER';
export const ENABLE_FEATURE_DISCLAIMER_DISPATCHER = 'ENABLE_FEATURE_DISCLAIMER_DISPATCHER';
export const ENABLE_FEATURE_STREAM_RECORDING = 'ENABLE_FEATURE_STREAM_RECORDING';
export const ENABLE_FEATURE_FILE_SHARE = 'ENABLE_FEATURE_FILE_SHARE';
export const ENABLE_FEATURE_SCREEN_SHARE = 'ENABLE_FEATURE_SCREEN_SHARE';
export const ENABLE_FEATURE_SMART_CONNECT = 'ENABLE_FEATURE_SMART_CONNECT';

export const CALLER_CHAT_SHOW = 'CALLER_CHAT_SHOW';
export const CALLER_CHAT_HIDE = 'CALLER_CHAT_HIDE';
export const CALLER_VIDEO_ACTIVATE_FULLSCREEN = 'CALLER_VIDEO_ACTIVATE_FULLSCREEN';
export const CALLER_VIDEO_DEACTIVATE_FULLSCREEN = 'CALLER_VIDEO_DEACTIVATE_FULLSCREEN';
export const SET_CHAT_LABELS = 'SET_CHAT_LABELS';
export const SET_CHAT_LANGUAGE = 'SET_CHAT_LANGUAGE';

export const CALLER_FILE_IS_BUSY = 'CALLER_FILE_IS_BUSY';
export const CALLER_FILE_IS_NOT_BUSY = 'CALLER_FILE_IS_NOT_BUSY';
export const CALLER_FILE_TRANSFER_STARTED = 'CALLER_FILE_TRANSFER_STARTED';
export const CALLER_FILE_TRANSFER_ENDED = 'CALLER_FILE_TRANSFER_ENDED';

export const CALLER_PAGE_LOADED = 'CALLED_PAGE_LOADED';
export const CALLER_PAGE_NOT_LOADED = 'CALLER_PAGE_NOT_LOADED';

export const CALLER_END_PAGE_LOADED = 'CALLER_END_PAGE_LOADED';
export const CALLER_END_PAGE_NOT_LOADED = 'CALLER_END_PAGE_NOT_LOADED';

export const CONNECTION_ESTABLISHED = 'CONNECTION_ESTABLISHED';
export const CONNECTION_LOST = 'CONNECTION_LOST';
export const CONNECTION_ENDED = 'CONNECTION_ENDED';

export const ADD_MEDIA_DEVICES = 'ADD_MEDIA_DEVICES';
export const CHANGE_DEVICE_ID = 'CHANGE_DEVICE_ID';
export const REMOVE_DEVICE_ID = 'REMOVE_DEVICE_ID';

export const SHOW_REFRESH = 'SHOW_REFRESH';

export const START_SESSION = 'START_SESSION';
export const STOP_SESSION = 'STOP_SESSION';
export const ADD_SESSION_LOG = 'ADD_SESSION_LOG';
export const ADD_SESSION_CHATMESSAGE = 'ADD_SESSION_CHATMESSAGE';
export const ADD_SESSION_CHAT_HISTORY = 'ADD_SESSION_CHAT_HISTORY';
export const ADD_SESSION_IMAGE = 'ADD_SESSION_IMAGE';
export const ADD_SESSION_MAP = 'ADD_SESSION_MAP';
export const ADD_SESSION_NOTES = 'ADD_SESSION_NOTES';
export const ADD_SESSION_RECORDING = 'ADD_SESSION_RECORDING';
export const ADD_SESSION_FILE = 'ADD_SESSION_FILE';
export const RESET_SESSION = 'RESET_SESSION';
export const ADD_SESSION_INFOS = 'ADD_SESSION_INFOS';
export const ADD_SESSION_GPS = 'ADD_SESSION_GPS';
export const DISABLE_SESSION_DOWNLOAD = 'DISABLE_SESSION_DOWNLOAD';
export const SET_PHONENUMBERS = 'SET_PHONENUMBERS';
export const ADD_SESSION_LANGUAGE = 'ADD_SESSION_LANUGAE';
export const ADD_SESSION_WHITELABEL_LOGO = 'ADD_SESSION_WHITELABEL_LOGO';
export const CHANGE_SESSION_IMAGE_FORMAT = 'CHANGE_SESSION_IMAGE_FORMAT';
export const UPDATE_SESSION_PHONENUMBER = 'UPDATE_SESSION_PHONENUMBER';

export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_SHAPE = 'CHANGE_SHAPE';
export const ADD_POINTS_DISPATCHER = 'ADD_POINTS_DISPATCHER';
export const ADD_POINTS_CALLER = 'ADD_POINTS_CALLER';
export const UNDO_LAST_PAINT = 'UNDO_LAST_PAINT';
export const DELETE_PAINT_CALLER = 'DELETE_PAINT_CALLER';
export const DELETE_PAINT = 'DELETE_PAINT';
export const ALLOW_PAINTING = 'ALLOW_PAINTING';
export const DISALLOW_PAINTING = 'DISALLOW_PAINTING';
export const RESET_PAINT = 'RESET_PAINT';
export const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE';
export const REMOVE_BACKGROUND_IMAGE = 'REMOVE_BACKGROUND_IMAGE';
export const SHOW_SCREENSHOT_DIALOGUE = 'SHOW_SCREENSHOT_DIALOGUE';
export const HIDE_SCREENSHOT_DIALOGUE = 'HIDE_SCREENSHOT_DIALOGUE';
export const SET_SCREENSHOT_DIALOGUE_CALLBACK = 'SET_SCREENSHOT_DIALOGUE_CALLBACK';
export const RESET_SCREENSHOT_DIALOGUE_CALLBACK = 'RESET_SCREENSHOT_DIALOGUE_CALLBACK';

export const ALLOW_STREAM_RECORDING_PERMISSION = 'ALLOW_STREAM_RECORDING_PERMISSION';
export const DISALLOW_STREAM_RECORDING_PERMISSION = 'DISALLOW_STREAM_RECORDING_PERMISSION';

export const ALLOW_PHOTO_PERMISSION = 'ALLOW_PHOTO_PERMISSION';
export const DISALLOW_PHOTO_PERMISSION = 'DISALLOW_PHOTO_PERMISSION';

export const ENABLE_CONFERENCE = 'ENABLE_CONFERENCE';
export const DISABLE_CONFERENCE = 'DISABLE_CONFERENCE';

export const UNMUTE_AUDIO = 'UNMUTE_AUDIO';
export const MUTE_AUDIO = 'MUTE_AUDIO';

export const MUTE_CALLER_MIC = 'MUTE_CALLER_MIC';
export const UNMUTE_CALLER_MIC = 'UNMUTE_CALLER_MIC';

export const SET_CONNECTION_UNSTABLE = 'SET_CONNECTION_UNSTABLE';
export const SET_CONNECTION_STABLE = 'SET_CONNECTION_STABLE';

export const ACTIVATE_NOTES = 'ACTIVATE_NOTES';
export const DEACTIVATE_NOTES = 'DEACTIVATE_NOTES';

export const START_STREAM_RECORDING = 'START_STREAM_RECORDING';
export const STOP_STREAM_RECORDING = 'STOP_STREAM_RECORDING';

export const ACTIVATE_WEB_RTC = 'ACTIVATE_WEB_RTC';
export const DEACTIVATE_WEB_RTC = 'DEACTIVATE_WEB_RTC';

export const ADD_TTL = 'ADD_TTL';

export const CHANGE_DEVICE_NAME = 'CHANGE_DEVICE_NAME';
export const REMOVE_DEVICE_NAME = 'REMOVE_DEVICE_NAME';

export const ACTIVATE_PHOTO_AUTO_DOWNLOAD = 'ACTIVATE_PHOTO_AUTO_DOWNLOAD';
export const DEACTIVATE_PHOTO_AUTO_DOWNLOAD = 'DEACTIVATE_PHOTO_AUTO_DOWNLOAD';

export const SET_PASSWORD_CHANGE_SENT = 'SET_PASSWORD_CHANGE_SENT';

export const ADD_VIDEO_BACKGROUND_IMAGE = 'ADD_VIDEO_BACKGROUND_IMAGE';
export const REMOVE_VIDEO_BACKGROUND_IMAGE = 'REMOVE_VIDEO_BACKGROUND_IMAGE';

export const ADD_CALLER_CONNECTION_QUALITY = 'ADD_CALLER_CONNECTION_QUALITY';

export const OPEN_INVITATION_PANEL = 'OPEN_INVITATION_PANEL';
export const CLOSE_INVITATION_PANEL = 'CLOSE_INVITATION_PANEL';

export const SET_LIVE_VIDEO_IS_LOADING = 'SET_LIVE_VIDEO_IS_LOADING';
export const UNSET_LIVE_VIDEO_IS_LOADING = 'UNSET_LIVE_VIDEO_IS_LOADING';

export const ACTIVATE_CONFERENCE_CHAT = 'ACTIVATE_CONFERENCE_CHAT';
export const DEACTIVATE_CONFERENCE_CHAT = 'DEACTIVATE_CONFERENCE_CHAT';
export const ACTIVATE_EXTERNAL_STREAM = 'ACTIVATE_EXTERNAL_STREAM';
export const DEACTIVATE_EXTERNAL_STREAM = 'DEACTIVATE_EXTERNAL_STREAM';
export const ACTIVATE_SMART_CONNECT = 'ACTIVATE_SMART_CONNECT';
export const DEACTIVATE_SMART_CONNECT = 'DEACTIVATE_SMART_CONNECT';
export const CALLER_TAB_IN_FOCUS = 'CALLER_TAB_IN_FOCUS';
export const CALLER_TAB_NOT_IN_FOCUS = 'CALLER_TAB_NOT_IN_FOCUS';