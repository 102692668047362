import { createBystander, sendSMSAPI } from "../api/backendApi";
import { normalizePhone } from "../helper/helper";

class DispatcherAuthManager {
    isPhoneNumberConsumed = false;
    phone = null;
    bystanderToken = null;
    backendSessionId = null;

    /**
     * create a bystander user
     * @param {string} phoneNumber
     */
    async createBystanderToken(phoneNumber) {
        this.bystanderToken = await createBystander(phoneNumber).then(result => {
            if (result) return result.token;
        });
        return this.bystanderToken;
    }

    /**
     * send a sms
     * @param {boolean} dryRun
     */
    async sendSMS(dryRun = false, isResendSMS = false, callerId) {
        const data = {
            token: this.bystanderToken,
            bystanderId: callerId,
            phoneNumber: normalizePhone(this.phone),
            dryRun: dryRun,
            isResendSMS: isResendSMS,
        };

        return await sendSMSAPI(data);
    }

    /**
     * init a new session
     * @param {string} phone
     */
    initSession(phone) {
        this.phone = phone;
        this.backendSessionId = Math.random().toString().substring(2);
    }
}

export let dispatcherAuthManager = new DispatcherAuthManager();