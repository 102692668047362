import { getURLParams } from '../../helper/rtcFlowHandling';
import { addCallerVideoStreamInDiv, addStreamToConferenceUserContainer } from '../../helper/streamHandling';
import {
    addCallerAudioStream,
    addCallerStream,
    addConferenceUserStream,
    addDispatcherAudioStream,
    addDispatcherBidiStream,
    addDispatcherStream,
    removeCallerAudioStream,
    removeCallerStream,
    removeConferenceUserStream,
    removeDispatcherAudioStream,
    removeDispatcherBidiStream,
    removeDispatcherStream,
} from '../../redux/actionCreators/actionCreators';
import store from '../../redux/store';
import { invitedUserWebRTCManager } from '../webrtcManagers/InvitedUserWebRTCManager';
import reduxStore from '../../redux/store';
import {
    activateBidiOtherDispatch,
    activateScreenshareDispatch,
    deactivateBidiOtherDispatch,
    deactivateScreenshareDispatch,
    deactivateVideoCallerDispatch,
} from '../../redux/actions/application';
import { WebRtcManagerType } from '../../types';
import { dispatchAddStreamInfo, dispatchRemoveStreamInfo } from '../../redux/actions/conferencing';
import { serializeStreamInfo } from '../../redux/utils/serializeStreamInfo';
import { dispatchToggleAudioMuted } from '../../redux/actions/invitedUsers';

// handlers for the conference user conversation
export const loadEventListenersConferenceUser = () => {
    invitedUserWebRTCManager.connectedConversation
        .on('streamAdded', addStream)
        .on('streamRemoved', removeStream)
        .on('streamListChanged', changeStream)
        .on('contactLeft', handleContactLeftConferenceUser);
};

export const unloadEventListenersConferenceUser = () => {
    if (invitedUserWebRTCManager.connectedConversation !== null) {
        invitedUserWebRTCManager.connectedConversation
            .removeListener('streamAdded', addStream)
            .removeListener('streamRemoved', removeStream)
            .removeListener('streamListChanged', changeStream)
            .removeListener('contactLeft', handleContactLeftConferenceUser);
    }
};

const addStream = stream => {
    const { callerId } = getURLParams();

    if (stream.contact.userData.username === callerId) {
        // incoming caller audio stream
        if (stream.type === 'audio') {
            store.dispatch(addCallerAudioStream(stream));
            handleIncomingAudio(stream);
        }
        // incoming caller video stream
        if (stream.type === 'video') {
            store.dispatch(addCallerStream(stream));
            addCallerVideoStreamInDiv(stream, false, false, 'caller-stream-container');
        }
    } else if (stream.contact.userData.username === 'dispatcher') {
        // incoming dispatcher audio stream
        if (stream.type === 'audio') {
            store.dispatch(addDispatcherAudioStream(stream));
            handleIncomingAudio(stream);
        }
        // incoming video streams from dispatcher
        if (stream.type === 'video') {
            reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                if (stream.streamId === streamInfo.streamId) {
                    // screensharing from dispatcher
                    if (streamInfo.isScreensharing) {
                        store.dispatch(addDispatcherStream(stream));
                        addStreamToConferenceUserContainer(stream, 'screen-share-container', true);
                        activateScreenshareDispatch(WebRtcManagerType.INVITED_USER);
                    }
                    // bidi from dispatcher
                    if (!streamInfo.isScreensharing) {
                        if (streamInfo.context.bidi) {
                            store.dispatch(addDispatcherBidiStream(stream));
                            addStreamToConferenceUserContainer(stream, 'bidi-stream-container', true);
                            activateBidiOtherDispatch();
                        }
                    }
                }
            });
        }
    } else {
        // incoming conference user audio streams
        store.dispatch(addConferenceUserStream(stream));
        handleIncomingAudio(stream);
    }
};

const handleIncomingAudio = stream => {
    addStreamToConferenceUserContainer(stream, 'audio-container');
};

const removeStream = stream => {
    if (reduxStore.getState().streamSlice.dispatcherAudioStream) {
        if (stream.streamId === reduxStore.getState().streamSlice.dispatcherAudioStream.streamId) {
            // dispatcher audio stream
            store.dispatch(removeDispatcherAudioStream(stream));
            handleRemovalOfIncomingStream(stream);
        }
    }

    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            if (stream.streamId === reduxStream.streamId) {
                // conference user stream
                store.dispatch(removeConferenceUserStream(stream));
                handleRemovalOfIncomingStream(stream);
            }
        });
    }

    if (reduxStore.getState().streamSlice.callerAudioStream) {
        if (stream.streamId === reduxStore.getState().streamSlice.callerAudioStream.streamId) {
            // caller audio stream
            store.dispatch(removeCallerAudioStream(stream));
            handleRemovalOfIncomingStream(stream);
        }
    }

    if (reduxStore.getState().streamSlice.dispatcherScreenshareStream) {
        if (stream.streamId === reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId) {
            // dispatcher screen share stream
            store.dispatch(removeDispatcherStream(stream));
            handleRemovalOfIncomingStream(stream);
            deactivateScreenshareDispatch();
        }
    }
    if (reduxStore.getState().streamSlice.dispatcherBidiStream) {
        if (stream.streamId === reduxStore.getState().streamSlice.dispatcherBidiStream.streamId) {
            // dispatcher bidi stream
            handleRemovalOfIncomingStream(stream);
            store.dispatch(removeDispatcherBidiStream(stream));
            deactivateBidiOtherDispatch();
        }
    }
    if (reduxStore.getState().streamSlice.callerStream) {
        if (stream.streamId === reduxStore.getState().streamSlice.callerStream.streamId) {
            // caller video stream
            store.dispatch(removeCallerStream(stream));
            handleRemovalOfIncomingStream(stream);
            deactivateVideoCallerDispatch();
        }
    }
};

const handleRemovalOfIncomingStream = stream => {
    if (stream.type === 'video') {
        if (
            reduxStore.getState().streamSlice.dispatcherBidiStream &&
            reduxStore.getState().streamSlice.dispatcherBidiStream.streamId &&
            reduxStore.getState().streamSlice.dispatcherBidiStream.streamId === stream.streamId
        ) {
            stream.removeFromDiv('bidi-stream-container', 'stream-media-' + stream.streamId);
            let bidiContainer = document.getElementById('bidi-stream-container');
            bidiContainer.innerHTML = '';
        } else if (
            reduxStore.getState().streamSlice.dispatcherScreenshareStream &&
            reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId &&
            reduxStore.getState().streamSlice.dispatcherScreenshareStream.streamId === stream.streamId
        ) {
            stream.removeFromDiv('screen-share-container', 'stream-media-' + stream.streamId);
            let screenshareContainer = document.getElementById('screen-share-container');
            screenshareContainer.innerHTML = '';
        } else if (
            reduxStore.getState().streamSlice.callerStream &&
            reduxStore.getState().streamSlice.callerStream.streamId &&
            reduxStore.getState().streamSlice.callerStream.streamId === stream.streamId
        ) {
            stream.removeFromDiv('caller-stream-container', 'stream');
            let callerVideoContainer = document.getElementById('caller-stream-container');
            callerVideoContainer.innerHTML = '';
        }
    } else {
        stream.removeFromDiv('audio-container', 'stream-media-' + stream.streamId);
    }
};

const changeStream = streamInfo => {
    if (streamInfo.isRemote === true) {
        if (streamInfo.listEventType === 'added') {
            dispatchAddStreamInfo(serializeStreamInfo(streamInfo));
            invitedUserWebRTCManager.connectedConversation
                .subscribeToMedia(streamInfo.streamId)
                .then(stream => {
                    console.log('subscribeToMedia succeeded');

                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                })
                .catch(err => {
                    console.error('subscribeToMedia error', err);
                });
        }
        if (streamInfo.listEventType === 'removed') {
            dispatchRemoveStreamInfo(streamInfo.streamId);
        }
        if (streamInfo.listEventType === 'updated') {
            for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                if (streamInfo.streamId === previousStreamInfo.streamId) {
                    dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                    dispatchAddStreamInfo(serializeStreamInfo(streamInfo));

                    // toggles the audio muted information in the conference user list
                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                }
            }
        }
    }
};

const handleContactLeftConferenceUser = contactInfo => {
    if (contactInfo.userData.username === 'dispatcher') {
        invitedUserWebRTCManager.onLeaveHandler();
    }
};
