import { cssVariables, logo, companyLogo, companyTitle, footerLogo } from 'theme';
import { getImage, getLanguageForDispatcher } from '../api/backendApi';
import {
    BACK_CAMERA_DETECTION_KEYWORDS,
    DEFAULT_LANGUAGE,
    DISPLAY_ONLY_IN_SESSION,
    FILE_TYPE_ENDINGS,
    FRONT_CAMERA_DETECTION_KEYWORDS,
    MAX_FILE_SIZE,
} from '../config';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { dispatchAddWhitelabelLogo } from '../redux/actions/session';
import { changeLanguageDispatcherDispatch } from '../redux/actions/texts';
import { PAINT_COLORS, PAINT_SHAPES } from '../redux/reducers/paint';
import reduxStore from '../redux/store/index';
import kpiLogIndex from '../kpiLogIndex/kpiLogIndex.json';
import kpiLogIndexVariables from '../kpiLogIndex/kpiLogIndexVariables.json';
import { errorLog, kpiLog } from '../helper/logging';
import { addPreviewFileDispatch } from '../redux/actions/files';
import { FILE_TYPE } from '../types';
import { dispatchActivateWakelock, dispatchDeactivateWakelock } from '../redux/actions/deviceScreen';
import { dispatcherAuthManager } from '../store/DispatcherAuthManager';

/**
 * create a uuid
 * @returns {string} uuid
 */
export const createUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

/**
 * parses a timestring into a date formate
 * @param {string | number} timeString
 * @returns {string}
 */
export const parseDate = timeString => {
    const dateObject = new Date(timeString);

    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObject.getDate()).slice(-2);

    return `${year}-${month}-${day} ${parseTime(timeString)}`;
};

/**
 * parse a time string into a readable format
 * @param {string} timeString
 * @returns {string} hh:mm:ss
 */
export const parseTime = timeString => {
    const dateObject = new Date(timeString);
    const hours = ('0' + dateObject.getHours()).slice(-2);
    const mins = ('0' + dateObject.getMinutes()).slice(-2);
    const secs = ('0' + dateObject.getSeconds()).slice(-2);
    return `${hours}:${mins}:${secs}`;
};

/**
 * calculate the difference between to timestamps
 * @param {number} now
 * @param {number} then
 * @returns {string} mm:ss
 */
export const calculateDifference = (now, then) => {
    const totalSeconds = Math.floor((now - then) / 1000);
    const minutes = ('0' + Math.floor(totalSeconds / 60)).slice(-2);
    const seconds = ('0' + Math.floor(totalSeconds % 60)).slice(-2);

    return `${minutes}:${seconds}`;
};

/**
 * get the browser title from the theme
 * @returns {string} browser title
 */
export const getBrowserTitle = () => {
    return process.env.REACT_APP_BROWSER_TITLE;
};

/**
 * get the title from the theme
 * @returns {string} title
 */
export const getTitle = () => {
    return process.env.REACT_APP_TITLE;
};

/**
 * get the logo from the theme
 * @returns {string} logo url
 */
export const getLogo = () => {
    return logo;
};

/**
 * get the company logo from the theme
 * @returns {string} logo url
 */
export const getCompanyLogo = () => {
    return companyLogo;
};

/**
 * get the footer logo from the theme
 * @returns {string} logo url
 */
// TODO: Fix 'footerLogo' is not exported from 'theme' error
export const getFooterLogo = () => {
    return footerLogo;
};

/**
 * get the company title from the theme
 * @returns {string} company title
 */
export const getCompanyTitle = () => {
    return companyTitle;
};

/**
 * change css variables from js to styles
 */
export const changeCSSVariablesTo = () => {
    Object.keys(cssVariables).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`, cssVariables[key]);
    });
};

/**
 * get params from an url
 * TODO MAYBE CHANGE TO: new URL()
 * @returns {object} key:value object
 */
export const getParamsFromUrl = () => {
    const paramText = window.location.search.substr(1);
    const params = paramText.split('&');
    let paramsObject = {};
    params.forEach(param => {
        const keyValue = param.split('=');
        paramsObject[keyValue[0]] = keyValue[1];
    });

    return paramsObject;
};

/**
 * obscure the given phonenumber
 * @param {string} phone
 * @returns {string} obscured phone
 */
export const obscurePhonenumber = phone => {
    return `${phone.slice(0, 3)} ... ${phone.slice(-3)}`;
};

/**
 * Parse the given label to check if front or back camera is detected
 * @param {string} label
 * @returns {string} parsed label
 */
export const parseCameraLabel = label => {
    const texts = reduxStore.getState().texts.texts;

    if (doesStringContainKeyword(label, FRONT_CAMERA_DETECTION_KEYWORDS)) {
        return `${replaceText(texts, 'camera.front')} (${label})`;
    }
    if (doesStringContainKeyword(label, BACK_CAMERA_DETECTION_KEYWORDS)) {
        return `${replaceText(texts, 'camera.back')} (${label})`;
    }
    return `${replaceText(texts, 'camera.none')} (${label})`;
};

/**
 * get a device that matches a given id or pattern
 * @param {string} id device id
 * @param {array} devices array of devices
 * @returns {object|null} selected device
 */
export const getDevice = (id, devices) => {
    const idFilteredDevices = devices.filter(device => device.id === id);
    if (idFilteredDevices.length > 0) return idFilteredDevices[0];

    if (idFilteredDevices.length === 0) {
        const backFacingDevices = devices.filter(device => doesStringContainKeyword(device.label, BACK_CAMERA_DETECTION_KEYWORDS));
        if (backFacingDevices.length > 0) return backFacingDevices[0];
        if (devices.length > 0) {
            return devices[devices.length - 1];
        }
        return null;
    }
};

/**
 * checks if the given phone number is valid
 * @param {string} phone
 * @returns {boolean}
 */
export const isValidPhone = phone => {
    const match = phone.match(/^(\+|00)[1-9]{1}[0-9]{3,14}$/gm);
    return match && match.length > 0;
};

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
export const hashFnv32a = (str, asString, seed) => {
    /*jshint bitwise:false */
    var i,
        l,
        hval = seed === undefined ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if (asString) {
        // Convert to 8 digit hex string
        return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
};

// utility functions for subbing in values to simple string templates in various places
export const formatUnicorn = (string, placeholders) => {
    let str = string.toString();
    if (placeholders.length) {
        const t = typeof placeholders[0];
        let key;
        const args = 'string' === t || 'number' === t ? Array.prototype.slice.call(placeholders) : placeholders[0];

        for (key in args) {
            str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
        }
    }

    return str;
};

/**
 * replaces a key from the text object with a value
 * @param {object} texts - object of translation strings
 * @param {string} id - key to replace
 * @param  {...any} placeholders
 * @returns {string} replaced value
 */
export const replaceText = (texts, id, ...placeholders) => {
    const hasElement = texts.hasOwnProperty(id); // eslint-disable-line no-prototype-builtins
    if (!hasElement) {
        return `${id}`;
    }
    let text = texts[id];
    if (typeof text === 'string') return formatUnicorn(text, placeholders);
    if (typeof text === 'object') return text.map(text => formatUnicorn(text, placeholders));
};

/**
 * reads a blob and converts it to base64
 * @param {blob} blob
 *
 * @returns {Promise} base64 encoded string
 */
export const convertBlobToBase64 = blob => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(blob);
    });
};

/**
 *
 * @param {string} dataURI
 * @returns {blob} object
 */

export const convertBase64ToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
};

/**
 * decodes a base64 string
 * @param {string} b64str - base64string
 * @returns decoded base 64 string
 */
export const b64DecodeUnicode = b64str => {
    return decodeURIComponent(
        atob(b64str)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
};

/**
 * checks if a string contains any of the given keywords
 * @param {string} str
 * @param {array} keywords
 * @returns {boolean}
 */
export const doesStringContainKeyword = (str, keywords) => {
    let contains = false;
    keywords.forEach(keyword => {
        if (str && str.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
            contains = true;
        }
    });

    return contains;
};

/**
 * gets the query parameter for the helpdesk links in the given language
 * @param {string} language
 * @returns {string}
 */
export const getHelpdeskLanguage = language => {
    switch (language) {
        case 'en':
            return '?lang=en';
        case 'fr':
            return '?lang=fr';
        case 'it':
            return '?lang=it';
        default:
            return '';
    }
};

/**
 * get the matching color for the initial character
 * @param {string} char single char of the color
 * @returns {string} color
 */

export const getColorFromChar = char => {
    const colorKey = Object.keys(PAINT_COLORS).filter(key => char === PAINT_COLORS[key].slice(-1))[0];
    return PAINT_COLORS[colorKey];
};

export const setWhiteLabelLogo = async () => {
    const whitelabelLogo = await getImage({ type: 'base64', endpoint: process.env.REACT_APP_IMAGE_DISPLAY_ENDPOINT });
    if (whitelabelLogo) {
        dispatchAddWhitelabelLogo({ logo: whitelabelLogo });
    }
};
export const setDispatcherLanguage = async () => {
    const language = await getLanguageForDispatcher();
    if (language) {
        changeLanguageDispatcherDispatch(language);
    } else {
        changeLanguageDispatcherDispatch(DEFAULT_LANGUAGE);
    }
};

/**
 * get the matching shape for the initial character
 * @param {string} char single char of the shape
 * @returns {string} shape
 */

export const getShapeFromChar = char => {
    const shapeKey = Object.keys(PAINT_SHAPES).filter(key => char === PAINT_SHAPES[key].substring(0, 1))[0];
    return PAINT_SHAPES[shapeKey];
};

/**
 * converts an svg to png
 */

export const convertSvgToPng = svgPath => {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.src = svgPath;

        img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const imgURI = canvas.toDataURL('image/png');
            resolve(imgURI);
        };
    });
};

/**
 * copies currentl url to clipboard
 */

export const copyLink = userType => {
    let url = window.location.href;
    if (userType === 'caller') {
        url = window.location.href.replace('disptchr', 'caller'); // eslint-disable-line
    } else if (userType === 'conference') {
        url = window.location.href.replace('disptchr', 'conference'); // eslint-disable-line
    }
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        try {
            navigator.clipboard.writeText(url);
            return true;
        } catch {
            addNotificationAndShowDispatch('error.cpy_lnk_err', 'error', DISPLAY_ONLY_IN_SESSION);
            return false;
        }
    }
};

/**
 * generate kpiLog based on given parameters. Values are pulled from kpLogIndex and kpiLogIndexVariables json files
 */

export const createKpiLog = (logRef, state = '', additionalStates) => {
    const kpiLogRef = kpiLogIndex.kpilog.find(log => log.logRef === logRef);
    let additionalInformation = '';
    let sessionId = dispatcherAuthManager.bystanderToken;

    if (additionalStates && additionalStates.sessionId) {
        sessionId = additionalStates.sessionId;
    }

    if (kpiLogRef) {
        if (kpiLogRef.additionalInformation) {
            additionalInformation = kpiLogRef.additionalInformation;
        }

        if (kpiLogRef.stateType) {
            additionalInformation = additionalInformation.replace(kpiLogRef.stateType, kpiLogIndexVariables[kpiLogRef.stateType][state]);
        }

        if (additionalStates) {
            for (const key in additionalStates) {
                if (kpiLogRef.additionalInformation) {
                    const varToReplace = '$' + key;
                    additionalInformation = additionalInformation.replace(varToReplace, additionalStates[key]);
                } else {
                    additionalInformation = additionalInformation + additionalStates[key];
                }
            }
        }

        kpiLog({
            eventId: kpiLogRef.eventId,
            time: Date.now(),
            message: kpiLogRef.message,
            sessionId: sessionId,
            additionalInformation: additionalInformation,
        });
    }
    return null;
};

/**
 * generate errorLog based on given parameters. Values are pulled from kpLogIndex and kpiLogIndexVariables json files
 */

export const createErrorLog = (logRef, state = '', additionalInformation = '', { additionalStates }) => {
    const kpiLogRef = kpiLogIndex.kpilog.find(log => log.logRef === logRef);
    if (kpiLogRef) {
        if (kpiLogRef.additionalInformation) {
            additionalInformation = kpiLogRef.additionalInformation;
        }

        if (additionalStates) {
            for (const key in additionalStates) {
                additionalInformation = kpiLogRef.additionalInformation.replace('$' + key, additionalStates[key]);
            }
        }

        errorLog({
            eventId: kpiLogRef.eventId,
            time: Date.now(),
            message: kpiLogRef.message,
            additionalInformation: additionalInformation,
            error: true,
        });
    }
    return null;
};

/**
 * checks if dispatcher is currently on start dashboard
 */
export const isOnStartPage = () => {
    const pathArray = window.location.pathname.split('/');
    const lastSegment = pathArray.pop();

    if (lastSegment === 'new') return true;
    return false;
};

/**
 * extract timestamp from filename of recorded stream
 */
export const extractTimestampFromFilename = recording => {
    let extractedInfo = recording.recordedFileName.slice(recording.recordedFileName.length - 17, recording.recordedFileName.length - 4);
    return extractedInfo;
};

export const getWebBrowser = () => {
    if (typeof InstallTrigger !== 'undefined') {
        return 'isFirefox';
    }

    // Check if the browser is Google Chrome
    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
        return 'isChrome';
    }

    // Check if the browser is Apple Safari on desktop
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !navigator.userAgent.includes('Mobile')) {
        return 'isSafari';
    }
};

export const dragAndDropFile = e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (isFileValid(file)) {
        const fileExtension = Object.entries(FILE_TYPE_ENDINGS).find(([key, value]) => key === file.type);

        addPreviewFileDispatch({ fileUrl: URL.createObjectURL(file), name: file.name, size: file.size, type: file.type, extension: fileExtension[1] });
    }
};

export const isFileValid = (file, userType = 'dispatcher') => {
    if (userType === 'caller') {
        createKpiLog('attachFileCallerInfo');
    } else {
        createKpiLog('attachFileDispatcherInfo');
    }

    if (Object.keys(FILE_TYPE_ENDINGS).indexOf(file.type) === -1) {
        if (userType === 'caller') {
            createKpiLog('fileTypeCallerInfo');
        } else {
            createKpiLog('fileTypeDispatcherInfo');
        }
        addNotificationAndShowDispatch('error.file.type', 'error', DISPLAY_ONLY_IN_SESSION);
        return false;
    }

    if (file.size >= MAX_FILE_SIZE) {
        if (userType === 'caller') {
            createKpiLog('fileSizeCallerInfo');
        } else {
            createKpiLog('fileSizeDispatcherInfo');
        }
        addNotificationAndShowDispatch('error.file.size', 'error', DISPLAY_ONLY_IN_SESSION);
        return false;
    }

    if (file.size < MAX_FILE_SIZE && Object.keys(FILE_TYPE_ENDINGS).indexOf(file.type) !== -1) {
        return true;
    } else {
        return false;
    }
};

export const getPasswordExpirationTime = passwordExpiration => {
    const texts = reduxStore.getState().texts.texts;
    const lastPasswordChangeDate = new Date(passwordExpiration.lastPasswordChange);
    const expirationDate = new Date(
        lastPasswordChangeDate.getFullYear(),
        lastPasswordChangeDate.getMonth() + passwordExpiration.expirationLength,
        lastPasswordChangeDate.getDate(),
        lastPasswordChangeDate.getHours(),
        lastPasswordChangeDate.getMinutes(),
        lastPasswordChangeDate.getSeconds()
    );
    const currentDate = new Date();
    const timeDifference = expirationDate - currentDate;
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30.44));
    const days = Math.floor((timeDifference % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24));
    const timeUntilExpiration = `${months} months, ${days} days`;
    if (months < 1 && days <= 7 && days >= 0) {
        if (texts) {
            addNotificationAndShowDispatch(
                `${replaceText(texts, 'password.change.partOne')} ${days} ${replaceText(texts, 'password.change.partTwo')}`,
                'warning'
            );
        }
    }

    return timeUntilExpiration;
};

export const formatDataSize = bytes => {
    if (bytes < 1024) {
        return bytes + ' bytes';
    } else if (bytes >= 1024 && bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(1) + ' kb';
    } else {
        return (bytes / (1024 * 1024)).toFixed(1) + ' mb';
    }
};

export const isWebGLAvailable = () => {
    var canvas = document.createElement('canvas');
    var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

    // if a context exists, WebGL is enabled. Double negation ensures this function returns a boolean.
    return !!gl; // eslint-disable-line no-extra-boolean-cast
};

/**
 * convert seconds to matching time unit as a string
 * @param {number} seconds
 * @returns {string}
 */
export const toMatchingTimeUnit = seconds => {
    const texts = reduxStore.getState().texts.texts;
    let convertedTimeUnit = '';
    if (seconds >= 86400 && seconds < 2628288) {
        convertedTimeUnit = replaceText(texts, 'time.unit.day') + ': ' + seconds / 60 / 60 / 24;
        return convertedTimeUnit;
    } else if (seconds >= 2628288) {
        convertedTimeUnit = replaceText(texts, 'time.unit.month') + ': ' + seconds / 60 / 60 / 24 / 30.42;
        return convertedTimeUnit;
    }
    return convertedTimeUnit;
};

/**
 * takes a number and return a reformatted number
 * @param {number}
 * @returns {number}
 */
export const normalizePhone = phone => {
    if (phone.indexOf('+') !== -1) phone = phone.replace('+', '00');
    return phone;
};

/**
 * custom timeout that runs a user defined function after
 * defined number of milliseconds have elapsed
 * @param {Function, number}
 * @returns {number}
 */
export const runAfterTimeHasElapsed = (userFunction = () => {}, timeInMilliseconds) => {
    let timer = setTimeout(() => {
        userFunction();
        return timer;
    }, timeInMilliseconds);
};

/**
 * attributes a category to a given file type
 * @param {object}
 * @returns {string}
 */
export const getFileTypeCategory = file => {
    const getCategory = (file, category) => {
        return Object.keys(category).some(mimeType => mimeType === file.type);
    };

    const imageType = {
        'image/jpg': 'jpeg',
        'image/jpeg': 'jpeg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/svg+xml': 'svg',
        'image/tiff': 'tiff',
        'image/webp': 'webp',
    };

    const documentType = {
        'application/pdf': 'pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/msword': 'doc',
        'text/plain': 'txt',
        'text/rtf': 'rtf',
        'application/vnd.oasis.opendocument.text': 'odt',
    };

    const presentationType = {
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.oasis.opendocument.presentation': 'odp',
    };

    const tableType = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/vnd.ms-excel': 'xls',
        'text/csv': 'csv',
        'application/vnd.oasis.opendocument.spreadsheet': 'ods',
        'application/json': 'json',
        'application/xml': 'xml',
    };

    const videoType = {
        'video/mp4': 'mp4',
        'video/avi': 'avi',
        'video/quicktime': 'mov',
        'video/x-ms-wmv': 'wmv',
        'video/x-matroska': 'mkv',
        'video/mpeg': 'mpeg',
        'video/ogg': 'ogv',
        'video/webm': 'webm',
    };

    const audioType = {
        'audio/mpeg': 'mp3',
        'audio/wav': 'wav',
        'audio/ogg': 'ogg',
    };

    switch (true) {
        case getCategory(file, imageType):
            return FILE_TYPE.IMAGE;
        case getCategory(file, documentType):
            return FILE_TYPE.DOCUMENT;
        case getCategory(file, presentationType):
            return FILE_TYPE.PRESENTATION;
        case getCategory(file, tableType):
            return FILE_TYPE.TABLE;
        case getCategory(file, videoType):
            return FILE_TYPE.VIDEO;
        case getCategory(file, audioType):
            return FILE_TYPE.AUDIO;
        default:
            return 'unknown';
    }
};

/**
 * Programmatically creates and clicks button to request screen wakelock
 */

export const createButtonAndToggleWakeLock = (storeType, state) => {
    const existingButton = document.getElementById('requestWakelock');
    if (existingButton) {
        document.body.removeChild(existingButton);
    }
    const button = document.createElement('button');
    button.id = 'requestWakelock';
    button.style.position = 'absolute';
    button.style.visibility = 'hidden';

    button.onclick = () => {
        if (state) {
            try {
                if (storeType.wakelock === null) {
                    navigator.wakeLock.request('screen').then(result => {
                        storeType.wakelock = result;
                        dispatchActivateWakelock();
                    });
                }
            } catch (err) {
                return;
            }
        } else {
            try {
                if (storeType.wakelock !== null) {
                    navigator.wakeLock.release('screen').then(() => {
                        storeType.wakelock = null;
                        dispatchDeactivateWakelock();
                        console.log('worked');
                    });
                }
            } catch (err) {
                return;
            }
        }
    };

    document.body.appendChild(button);

    if (button) {
        button.click();
    }
};