import { clearGPSInterval, startGPSInterval } from "../../api/geplocationApi";
import { BATTERY_INFO_INTERVAL, DEVICE_INFO_INTERVAL, FOCUS_FEATURE_TIMEOUT } from "../../config";
import { tabletPatternRegex } from "../../regex";
import { callerWebRTCManager } from "../webrtcManagers/CallerWebRTCManager";
import reduxStore from '../../redux/store';
import { dispatchActivateFocusControls, dispatchDeactivateFocusControls } from "../../redux/actions/focus";
import { runAfterTimeHasElapsed } from "../../helper/helper";
import { WebRtcMessageCaller } from "../../types";
import store from "../../redux/store";
import { setIsHeadMountedDisplayDevice } from "../../redux/slices/sessionHandlingSlice";

    /**
     * start sending gps coordinats
     */
    export const sendCoords = () => {
        if (callerWebRTCManager.useGPS) {
            startGPSInterval();
        } else {
            clearGPSInterval();
        }
    }


    /**
     * start sending battery info
     */
    export const sendBatteryInfo = () => {
        getAndSendBatteryInfo();
        callerWebRTCManager.batteryInterval = window.setInterval(() => {
            getAndSendBatteryInfo();
        }, BATTERY_INFO_INTERVAL);
    }

    /**
     * get battery info
     */
    export const getAndSendBatteryInfo = () => {
        if ('getBattery' in navigator) {
            // TODO fix type
            (navigator as any).getBattery().then(battery => {
                var level = battery.level;
                const message = {
                    data: WebRtcMessageCaller.BATTERY,
                    level: level,
                };
                callerWebRTCManager.sendMessage(message);
            });
        } else {
            const message = {
                data: 'batteryError',
                message: 'get battery api not available',
            };
            callerWebRTCManager.sendMessage(message);
        }
    }

    /**
     * send is head mounted display device
     */
    export const sendIsHeadMountedDevice = () => {
        const message = {
            data: WebRtcMessageCaller.IS_HEAD_MOUNTED_DEVICE,
        };

        callerWebRTCManager.sendMessage(message);
    }

    // no need to test
    /**
     * start sending media infos
     */
    export const sendMediaInfos = () => {
        getMediaInfos();
        callerWebRTCManager.mediaInterval = window.setInterval(() => {
            getMediaInfos();
        }, DEVICE_INFO_INTERVAL);
    }

    /**
     * get media infos
     */
    export const getMediaInfos = () => {
        callerWebRTCManager.mediaDevices = callerWebRTCManager.userAgent.getUserMediaDevices();
        // TODO fix typing
        const cams = Object.values((callerWebRTCManager.mediaDevices as any).videoinput).map(function (videoInput) {
            return { label: (videoInput as any).label, id: (videoInput as any).id };
        });
        // only send if something changed
        if (JSON.stringify(cams) !== JSON.stringify(callerWebRTCManager.cams)) {
            callerWebRTCManager.cams = cams;
            const message = { data: WebRtcMessageCaller.DEVICE_INFO, devices: cams };
            callerWebRTCManager.sendMessage(message);
        }
    }

    /**
     * detect head mounted display device
     */
    export const detectHmdOrWearableDevice = () => {
        const scale = window.devicePixelRatio;

        if (navigator.userAgent.includes('glass') || navigator.userAgent.includes('oculus') || navigator.userAgent.includes('vive')) {
            sendIsHeadMountedDevice();
            store.dispatch(setIsHeadMountedDisplayDevice());
            return;
        }

        // TODO: fix typing
        if ((navigator as any).xr) {
            (navigator as any).xr
                .isSessionSupported('immersive-vr')
                .then(isSupported => {
                    const userAgent = navigator.userAgent.toLowerCase();
                    if (isSupported && scale < 2 && !tabletPatternRegex.test(userAgent)) {
                        sendIsHeadMountedDevice();
                        store.dispatch(setIsHeadMountedDisplayDevice());
                    }
                    return;
                })
                .catch(err => {
                    console.error('Error checking HMD support:', err);
                });
        }
    }

    /**
     * send connection infos
     */
    export const sendConnectionInfos = () => {
        callerWebRTCManager.userAgent.fetchNetworkInformation().then(function (data) {
            const message = {
                data: WebRtcMessageCaller.NETWORK_INFO,
                testServer: data.testServer,
                httpPing: data.httpPing,
                upload: data.upload,
                download: data.download,
            };
            callerWebRTCManager.sendMessage(message);
        });
    }

    /**
     * Sends a message with client-system infos.
     */

    export const sendSystemInfo = () => {
        const info = {
            data: WebRtcMessageCaller.SYSTEM,
            timeOpened: new Date(),
            timezone: new Date().getTimezoneOffset() / 60,
            referrer: document.referrer,
            previousSites: window.history.length,
            browserName: navigator.appName,
            browserEngine: navigator.product,
            browserVersion1a: navigator.appVersion,
            browserVersion1b: navigator.userAgent,
            browserLanguage: navigator.language,
            browserOnline: navigator.onLine,
            browserPlatform: navigator.platform,
            javaEnabled: navigator.javaEnabled(),
            dataCookiesEnabled: navigator.cookieEnabled,
            dataCookies1: document.cookie,
            // TODO: fix function param incompatibility
            /* @ts-ignore */
            dataCookies2: decodeURIComponent(document.cookie.split(';')),
            dataStorage: localStorage,
            sizeScreenW: window.screen.width,
            sizeScreenH: window.screen.height,
            // TODO: fix typing
            sizeDocW: (document as any).width,
            sizeDocH: (document as any).height,
            sizeInW: window.innerWidth,
            sizeInH: window.innerHeight,
            sizeAvailW: window.screen.availWidth,
            sizeAvailH: window.screen.availHeight,
            scrColorDepth: window.screen.colorDepth,
            scrPixelDepth: window.screen.pixelDepth,
        };
        callerWebRTCManager.sendMessage(info);
    }

    /**
     * send caller language
     */

    export const sendCallerLanguage = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.UPDATE_CALLER_LANGUAGE,
            language: reduxStore.getState().texts.language,
        });
    }

    /**
     * send request join permission
     */
    export const sendRequestJoinPermission = () => {
        const message = {
            data: WebRtcMessageCaller.REQUEST_JOIN_PERMISSION,
        };

        callerWebRTCManager.sendMessage(message);
    }

    /**
     * send video not allowed message
     */
    export const sendVideoNotAllowed = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.VIDEO_NOT_AVAILABLE,
        });
    }

    /**
     * send snapshot permission message
     * @param {boolean} permission
     */
    export const sendPhotoPermission = (permission) => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.PHOTO_PERMISSION,
            permission,
        });
    }

    /**
     * send stream recording permission message
     * @param {boolean} permission
     */
    export const sendStreamRecordingPermission = (permission) => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.STREAM_RECORDING_PERMISSION,
            permission,
        });
    }

    /**
     * send file select start
     * @returns
     */
    export const sendFileIsBusy = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.HD_FILE_CALLER_IS_BUSY,
        });
    }

    /**
     * send file start
     * @returns
     */
    export const sendFileStart = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.HD_FILE_TRANSFER_STARTED,
        });
    }

    /**
     * send caller paint points
     */
    export const sendCallerPaintPoints = (points) => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.CALLER_PAINT_POINTS,
            points,
        });
    }

    /**
     * allowPainting
     * @param {boolean} activeState
     */
    export const sendAllowPaintingCaller = (activeState) => {
        const message = {
            data: WebRtcMessageCaller.ALLOW_PAINTING,
            state: activeState,
        };
        callerWebRTCManager.sendMessage(message);
    }

    /**
     * delete caller paint points
     * @param {boolean} activeState
     */
    export const sendDeletePaintPointsCaller = () => {
        const message = {
            data: WebRtcMessageCaller.DELETE_PAINT_POINTS_CALLER,
        };
        callerWebRTCManager.sendMessage(message);
    }

    /**
     * send toggle feature focus
     * @param {string} activeState
     */
    export const sendToggleFeatureFocus = (activeState) => {
        const message = {
            data: WebRtcMessageCaller.TOGGLE_FEATURE_FOCUS,
            state: activeState,
        };
        callerWebRTCManager.sendMessage(message);
        dispatchDeactivateFocusControls();
        runAfterTimeHasElapsed(dispatchActivateFocusControls, FOCUS_FEATURE_TIMEOUT);
    }

    // decline disclaimer and end call
    export const declineDisclaimerAndEndCall = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.DECLINE_DISCLAIMER,
        });
        callerWebRTCManager.endCall();
    }

    // accept disclaimer
    export const acceptDisclaimerForCaller = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.ACCEPT_DISCLAIMER,
        });
    }

    // disclaimer is visible
    export const disclaimerVisible = () => {
        callerWebRTCManager.sendMessage({
            data: WebRtcMessageCaller.CALLER_DISCLAIMER_VISIBLE,
        });
    }

    // send caller details only after disclaimer status is handled
    export const sendCallerDetails = () => {
        // small delay between sending message to increase reliability of message reception
        runAfterTimeHasElapsed(sendMediaInfos, 200);
        runAfterTimeHasElapsed(sendConnectionInfos, 200);
        runAfterTimeHasElapsed(sendBatteryInfo, 200);
        runAfterTimeHasElapsed(sendSystemInfo, 200);
        runAfterTimeHasElapsed(sendCallerLanguage, 200);
    }